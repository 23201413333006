import { put, select } from 'redux-saga/effects';
import { getPublicTours } from 'services/publicTours';
import ROLES from 'consts/role';
import { emptyPublicTourState } from 'views/Modal/data';

import * as ACTION_TYPES from '../actionTypes';

const selectUser = ({ user }) => user;
const selectPublicTourId = ({ publicTour }) => publicTour.id;

function* checkPublicTourExist(publicTours) {
  const { role } = yield select(selectUser);

  if (role === ROLES.ADMIN) {
    const publicTourId = yield select(selectPublicTourId);
    const hasPublicTour = publicTours.find(
      (publicTour) => publicTour.id === publicTourId
    );
    if (!hasPublicTour) {
      // publicTour save in local storage is not available in this user
      const { id, name, active, isPublic } = publicTours[0] || {
        id: '',
        name: '',
        active: true,
        isPublic: true,
      };
      yield put({
        type: ACTION_TYPES.SET_PUBLIC_TOUR,
        publicTour: { id, name, active, isPublic },
      });
    }
  } else {
    yield put({
      type: ACTION_TYPES.SET_PUBLIC_TOUR,
      publicTour: emptyPublicTourState,
    });
  }
}

export function* loadPublicToursByRole() {
  // Get list Public Tours by role
  let res = null;
  res = yield getPublicTours();
  const publicTours = res.data.data;

  // Put Customers data to store
  if (publicTours && publicTours.length !== 0) {
    yield put({
      type: ACTION_TYPES.SET_PUBLIC_TOURS,
      publicTours,
    });
  }

  yield checkPublicTourExist(publicTours);
}
