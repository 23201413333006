import LOCAL_KEY from 'consts/commons';

let customerData = JSON.parse(
  localStorage.getItem(LOCAL_KEY.CUSTOMER) || 'null'
);

if (!customerData || !customerData.id) {
  customerData = { id: '', companyName: '' };
}

let publicTourData = JSON.parse(
  localStorage.getItem(LOCAL_KEY.PUBLIC_TOUR) || 'null'
);

if (!publicTourData || !publicTourData.id) {
  publicTourData = { id: '', name: '' };
}

let projectData = JSON.parse(localStorage.getItem(LOCAL_KEY.PROJECT) || 'null');
if (!projectData || !projectData.id) {
  projectData = { id: '', name: '' };
}

let tourData = JSON.parse(localStorage.getItem(LOCAL_KEY.TOUR) || 'null');
if (!tourData || !tourData.id) {
  tourData = { id: '', name: '' };
}

const userId = localStorage.getItem(LOCAL_KEY.USER_ID) || null;

const initialState = {
  ready: false,
  user: {
    id: userId,
    role: null,
    name: '',
  },
  customers: [],
  publicTours: [],
  projects: [],
  tours: [],
  customer: customerData,
  publicTour: publicTourData,
  project: projectData,
  tour: tourData,
  threed: {
    images: [],
    imageType: '', // [canvas, url]
    defaultOrientation: null, // [x, y, z]
    lensFlareLocation: [], // [x, y, z]
    previewImage: null,
  },
  tourNameModal: {
    open: false,
  },
  sceneNameModal: {
    open: false,
  },
  addSceneModal: {
    open: false,
  },
  hasToursDataFromApi: false,
  hasProjectsDataFromApi: false,
  isShowClientWelcomePopup: true,
  headerModalOpen: {
    customer: false,
    publicTour: false,
    project: false,
    tour: false,
  },
  websites: [],
  mediaId: null,
  isShowMFHp: true,
  isShowInline: false,
  rotateAxis: null,
  originalRotate: {},
  limitOrbit: null,
};

export default initialState;
