import urls from 'consts/urls';
import api from 'helpers/api';

export const getProjectsBySearchQuery = (customerId) => {
  return api.patch(urls.PROJECTS, customerId);
};

export const getProjectDetail = (projectId) => {
  return api.get(`${urls.PROJECTS}/${projectId}`);
};

export const addProject = (data) => {
  return api.post(urls.PROJECTS, data);
};

export const softDelete = (customerId, projectId) => {
  const data = {
    customerId: customerId,
    projects: [projectId],
  };
  return api.post(urls.PROJECT_SOFT_DELETE, data);
};

export const updateProject = (data) => {
  const dataID = data.id;
  delete data.id;
  return api.put(urls.PROJECTS + '/' + dataID, data);
};

export const addProjectMenu = (projectId, menu) => {
  const data = {
    projectId: projectId,
    ...menu,
  };
  return api.post(urls.PROJECTS_MENU, data);
};

export const getProjectMenuDetail = (projectId) => {
  return api.get(`${urls.PROJECTS_MENU}/${projectId}`);
};

export const generateProjectJson = (id) => {
  return api.post(`${urls.PROJECTS}/${id}/json`);
};

export const getGenerateProjectJson = (id) => {
  const data = {
    projects: [id],
  };

  return api.patch(`${urls.PROJECTS}/json`, data);
};

export const publishProjectJson = (jsonId) => {
  return api.post(`${urls.PROJECTS}/${jsonId}/publish`);
};

export const getAllPublishedProjects = (data) => {
  return api.patch(urls.PROJECTS, data);
};
