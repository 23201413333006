import { isMobile } from 'react-device-detect';

export const MEDIA = {
  IMAGE: 'Image',
  INFO_TAG: 'InfoTag',
  YOUTUBE: 'Youtube',
  VIMEO: 'Vimeo',
  VIDEO: 'Video',
  AUDIO: 'Audio',
  GALLERY: 'Gallery',
  MATTERPORT: 'Matterport',
  MAP_FLOOR_PLAN: 'MapFloorPlan',
  GOOGLE_360: 'Google360',
  CUSTOM: 'Custom',
  TYPEFORM: 'TypeForm',
  LOGO_OVERRIDE: 'LogoOverride',
  TOUR_AUDIO: 'TourAudio',
  AGENT_PROFILE: 'AgentProfile',
  PROPERTY_PROFILE: 'PropertyProfile',
  SHAPE_SPARK: 'ShapeSpark',
  SKETCH_FAB: 'SketchFab',
  MODEL_3D: 'Model3D',
  PDF: 'PDF',
  LUMA: 'Luma',
};

export const MEDIA_TYPES = {
  Image: 'Info Box',
  InfoTag: 'Info Tag',
  Youtube: 'Video Box',
  Vimeo: 'Vimeo',
  Video: 'Video',
  Audio: 'Audio / Sound',
  Gallery: 'Gallery',
  Matterport: 'Matterport',
  MapFloorPlan: '2D Map/Floor Plan',
  Google360: 'Google 360',
  Custom: 'Custom',
  TypeForm: 'TypeForm',
  LogoOverride: 'Logo Override',
  TourAudio: 'Tour Audio',
  AgentProfile: 'Agent Profile',
  PropertyProfile: 'Property Profile',
  ShapeSpark: 'Shape Spark',
  SketchFab: 'Sketch Fab',
  Model3D: 'Model 3D',
  PDF: 'PDF',
  Luma: 'Luma’s NeRF',
};

export const CUSTOM_TYPES = {
  TYPEFORM: MEDIA.TYPEFORM,
  LOGO_OVERRIDE: MEDIA.LOGO_OVERRIDE,
  GOOGLE_360: MEDIA.GOOGLE_360,
  VIDEO: MEDIA.VIDEO,
  // AUDIO: MEDIA.AUDIO,
  SHAPESPARK: MEDIA.SHAPE_SPARK,
  SKETCH_FAB: MEDIA.SKETCH_FAB,
  MODEL_3D: MEDIA.MODEL_3D,
  LUMA: MEDIA.LUMA,
};

export const CUSTOM_MEDIA_TYPE = Object.values(CUSTOM_TYPES);

export const MEDIA_TYPES_SELECT = [
  { value: MEDIA.IMAGE, label: MEDIA_TYPES[MEDIA.IMAGE] },
  { value: MEDIA.INFO_TAG, label: MEDIA_TYPES[MEDIA.INFO_TAG] },
  { value: MEDIA.VIDEO, label: MEDIA_TYPES[MEDIA.VIDEO] },
  { value: MEDIA.AUDIO, label: MEDIA_TYPES[MEDIA.AUDIO] },
  { value: MEDIA.PDF, label: MEDIA_TYPES[MEDIA.PDF] },
  { value: MEDIA.GALLERY, label: MEDIA_TYPES[MEDIA.GALLERY] },
  { value: MEDIA.MATTERPORT, label: MEDIA_TYPES[MEDIA.MATTERPORT] },
  { value: MEDIA.MAP_FLOOR_PLAN, label: MEDIA_TYPES[MEDIA.MAP_FLOOR_PLAN] },
  { value: MEDIA.GOOGLE_360, label: MEDIA_TYPES[MEDIA.GOOGLE_360] },
  { value: MEDIA.TYPEFORM, label: MEDIA_TYPES[MEDIA.TYPEFORM] },
  // { value: MEDIA.LOGO_OVERRIDE, label: MEDIA_TYPES[MEDIA.LOGO_OVERRIDE] },
  { value: MEDIA.SHAPE_SPARK, label: MEDIA_TYPES[MEDIA.SHAPE_SPARK] },
  // { value: MEDIA.TOUR_AUDIO, label: MEDIA_TYPES[MEDIA.TOUR_AUDIO] },
  { value: MEDIA.AGENT_PROFILE, label: MEDIA_TYPES[MEDIA.AGENT_PROFILE] },
  { value: MEDIA.PROPERTY_PROFILE, label: MEDIA_TYPES[MEDIA.PROPERTY_PROFILE] },
  { value: MEDIA.SKETCH_FAB, label: MEDIA_TYPES[MEDIA.SKETCH_FAB] },
  { value: MEDIA.MODEL_3D, label: MEDIA_TYPES[MEDIA.MODEL_3D] },
  { value: MEDIA.LUMA, label: MEDIA_TYPES[MEDIA.LUMA] },
];

export const MOBILE_MEDIA_TYPES_SELECT = [
  { value: MEDIA.IMAGE, label: MEDIA_TYPES[MEDIA.IMAGE] },
  { value: MEDIA.INFO_TAG, label: MEDIA_TYPES[MEDIA.INFO_TAG] },
];

export const SELECT_OPTIONS = isMobile
  ? MOBILE_MEDIA_TYPES_SELECT.map(({ value, label }) => ({
      id: value,
      name: label,
    }))
  : MEDIA_TYPES_SELECT.map(({ value, label }) => ({ id: value, name: label }));

export const SELECT_OPTIONS_FILTERED = {
  AGENT_PROFILE: SELECT_OPTIONS.filter(({ id }) => id === MEDIA.AGENT_PROFILE),
  PROPERTY_PROFILE: SELECT_OPTIONS.filter(
    ({ id }) => id === MEDIA.PROPERTY_PROFILE
  ),
  SCENE_MEDIA: SELECT_OPTIONS.filter(
    ({ id }) => id !== MEDIA.AGENT_PROFILE && id !== MEDIA.PROPERTY_PROFILE
  ),
};

export const FEATURED_MEDIA = {
  MAP: 'map',
  FORM: 'form',
  AUDIO: 'audio',
};
