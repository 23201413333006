import configs from 'configs';

export const DEFAULT_MAPBOX_STYLE = 'mapbox/satellite-streets-v12';

export const MAP_STYLES = [
  {
    style: DEFAULT_MAPBOX_STYLE,
    label: 'Satellite Streets (Default)',
    image: configs.baseUrl + '/assets/images/mapstyles/satellite-streets.png',
  },
  {
    style: 'mapbox/streets-v12',
    label: 'Streets',
    image: configs.baseUrl + '/assets/images/mapstyles/streets.png',
  },
  {
    style: 'mapbox/outdoors-v12',
    label: 'Outdoors',
    image: configs.baseUrl + '/assets/images/mapstyles/outdoors.png',
  },
  {
    style: 'mapbox/light-v11',
    label: 'Light',
    image: configs.baseUrl + '/assets/images/mapstyles/light.png',
  },
  {
    style: 'mapbox/dark-v11',
    label: 'Dark',
    image: configs.baseUrl + '/assets/images/mapstyles/dark.png',
  },
  {
    style: 'mapbox/satellite-v9',
    label: 'Satellite',
    image: configs.baseUrl + '/assets/images/mapstyles/satellite.png',
  },
  {
    style: 'mapbox/navigation-day-v1',
    label: 'Navigation Day',
    image: configs.baseUrl + '/assets/images/mapstyles/navigation-day.png',
  },
  {
    style: 'mapbox/navigation-night-v1',
    label: 'Navigation Night',
    image: configs.baseUrl + '/assets/images/mapstyles/navigation-night.png',
  },
  {
    style: '',
    label: 'Other',
    image: configs.baseUrl + '/assets/images/mapstyles/other.png',
  },
];

export const DEFAULT_MODAL_MAP_COORDS = {
  mapLong: 106.6503962,
  mapLat: 10.8010504,
  mapAlt: 14.5,
};
