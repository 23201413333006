export const emptyCustomerState = {
  id: '',
  companyName: 'Select Customer',
};

export const emptyPublicTourState = {
  id: '',
  name: '',
};

export const emptyProjectState = {
  id: '',
  name: '',
};

export const emptyTourState = {
  id: '',
  name: '',
  friendlyName: '',
};
