import { toast } from 'react-toastify';
import { isNumber, pick, isEmpty, debounce } from 'lodash';
import * as Yup from 'yup';

import { isPublicTourPath } from 'features/publicTour/utils';

import {
  SCENE_RATIO_VALUE,
  VIEWING_ANGLE,
  TOUR_MAP_COORDINATES,
  DEFAULT_TOUR_DATA,
} from './constants';
import { SITE_SCOPE } from 'consts';
import { showToast } from 'utils/toast';

import { ACCESS_CODE_REGEX } from 'features/tours/constants';

const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API;

export const checkEnableTour = (usage, scopes) => {
  if (scopes.scopeType === SITE_SCOPE.NOT_ALLOW) return false;
  if (scopes.scopeType === SITE_SCOPE.UNLIMITED) return true;
  if (scopes.tour.value === SITE_SCOPE.UNLIMITED) return true;
  if (usage.tour.value + 1 > scopes.tour.value) {
    return false;
  }

  return !(usage.scene.value + usage.scene.limitPerTour > scopes.scene.value);
};

export const ensureDefaultViewingAngle = (viewingAngle) =>
  viewingAngle < VIEWING_ANGLE.MIN || viewingAngle > VIEWING_ANGLE.MAX
    ? VIEWING_ANGLE.DEFAULT
    : viewingAngle;

export const ensureSceneRatio = (sceneRatio) =>
  SCENE_RATIO_VALUE.includes(sceneRatio)
    ? DEFAULT_TOUR_DATA.sceneRatio
    : sceneRatio.toString();

export const ensureMapLightIntensity = (data) => (isNumber(data) ? data : 0.5);

export const ensureTourData = (tourData) => {
  const newTour = {};
  Object.keys(tourData).forEach((key) => {
    if (typeof tourData[key] === 'string') {
      newTour[key] = tourData[key] || '';
    } else if (typeof tourData[key] === 'undefined') {
      newTour[key] = '';
    } else {
      newTour[key] = tourData[key];
    }
  });

  //mapCoordinates
  newTour.mapLong = newTour.mapCoordinates[0] || null;
  newTour.mapLat = newTour.mapCoordinates[1] || null;
  newTour.mapAlt = newTour.mapCoordinates[2] || null;
  newTour.mapLightIntensity = ensureMapLightIntensity(
    newTour.mapLightIntensity
  );
  //color
  newTour.mapLightColor = newTour.mapLightColor || 'white';
  newTour.lightboxBGColor = newTour.lightboxBGColor1 || 'black';
  newTour.lightboxBodyTextColor = newTour.lightboxBodyTextColor || 'while';
  newTour.calloutHeaderColor = newTour.calloutHeaderColor || 'black';
  newTour.calloutSubHeaderColor = newTour.calloutSubHeaderColor || 'white';
  //boolen data
  newTour.active = newTour.active || false;
  newTour.enableGyroscope = newTour.enableGyroscope || false;
  newTour.autorotate = newTour.autorotate || false;
  newTour.lightboxBlur = newTour.lightboxBlur || false;
  //sceneRatio
  newTour.sceneRatio = ensureSceneRatio(newTour.sceneRatio);
  //defaultViewingAngle
  newTour.defaultViewingAngle = ensureDefaultViewingAngle(
    newTour.defaultViewingAngle
  );
  //ipv4 address
  newTour.allowedIp = newTour.allowedIp || '';
  newTour.passcode = newTour.passcode || '';
  //menu style
  newTour.menuStyle = newTour.menuStyle || 'default';
  //hotspot style
  newTour.hotspotStyle = newTour.hotspotStyle || 'default';
  return newTour;
};

export const ensureTourSubmit = ({
  tourData,
  newUploadLogoUrl,
  newIntroImageUrl,
  newTripodMarkerUrl,
  customerId,
  isPublic,
  createdBy,
  introVideoUrl,
}) => {
  const tourSubmit = {
    ...tourData,
    lightboxBGColor1: tourData.lightboxBGColor,
    lightboxBGColor2: tourData.lightboxBGColor,
    lightboxBGColor3: tourData.lightboxBGColor,
    mapCoordinates:
      tourData.mapLong && tourData.mapLat && tourData.mapAlt
        ? [tourData.mapLong, tourData.mapLat, tourData.mapAlt]
        : [],
    customerId: tourData.customerId || customerId,
    isPublic: tourData.isPublic || isPublic,
    createdBy: tourData.createdBy || createdBy,
    logoUrl: !isEmpty(newUploadLogoUrl) ? newUploadLogoUrl : null,
    introImageUrl: !isEmpty(newIntroImageUrl) ? newIntroImageUrl : null,
    tripodMarkerUrl: !isEmpty(newTripodMarkerUrl) ? newTripodMarkerUrl : null,
    introVideoUrl: !isEmpty(introVideoUrl) ? introVideoUrl : null,
  };

  return tourSubmit;
};

export const showErrorUpdateTour = (error, t) => {
  const toastId = toast.info(t('info.tour.saving', { ns: 'toast' }));
  const errors = error.response.data;
  if (errors.length > 0) {
    showToast({ toastId, type: 'error', message: errors[0].error });
  } else {
    showToast({ toastId, type: 'error', message: errors.error });
  }
  if (errors?.data?.error) {
    showToast({ toastId, type: 'error', message: errors.data.error });
  }
};

export const filterMapCoordinatesData = (tourData) => {
  return pick(tourData, TOUR_MAP_COORDINATES);
};

export const validateMapStyle = async (customStyle) => {
  try {
    const mapBoxStylePrefix = 'mapbox://styles/';
    let style;
    if (customStyle.startsWith(mapBoxStylePrefix)) {
      style = customStyle.slice(mapBoxStylePrefix.length);
    } else style = customStyle;
    const url = `https://api.mapbox.com/styles/v1/${style}/static/100,10,0/16x16?access_token=${ACCESS_TOKEN}`;
    const res = await fetch(url);
    return res.ok;
  } catch (error) {
    console.error('Error while validating the map style:', error);
    return false;
  }
};

const debouncedValidateMapStyle = debounce(async (value, callback) => {
  const isValid = await validateMapStyle(value);
  callback(isValid);
}, 1000);

export const debouncedValidateMapStyleWithPromise = (value) => {
  if (value) {
    return new Promise((resolve) => {
      debouncedValidateMapStyle(value, resolve);
    });
  }
};

const ipv4ListValidator = (ipv4String) => {
  if (!ipv4String) {
    // Không có dữ liệu đầu vào, không cần kiểm tra
    return true;
  }

  // Tách chuỗi thành mảng các địa chỉ IPv4 bằng dấu ,
  const ipv4Array = ipv4String
    .split(',')
    .map((ip) => ip.trim())
    .filter((element) => element !== '');

  // Tạo schema Yup cho mỗi địa chỉ IPv4
  const ipv4Schema = Yup.string().matches(
    /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
    'Địa chỉ IPv4 không hợp lệ'
  );

  // Kiểm tra từng địa chỉ IPv4
  for (const ip of ipv4Array) {
    if (!ipv4Schema.isValidSync(ip)) {
      return false;
    }
  }

  return true;
};

export const getTourValidateSchema = (tourNames) => {
  return Yup.object().shape({
    name: Yup.string()
      .required('error.common.field_required')
      .max(100, 'error.tour.max_length')
      .test(
        'is-tour-name-unique',
        'error.tour.name_exists',
        (value) => !tourNames.includes(value)
      ),
    accessCode: Yup.string()
      .required('error.common.field_required')
      .matches(ACCESS_CODE_REGEX, 'error.tour.invalid_accesscode')
      .min(4, 'error.tour.min_length'),
    mapLong: Yup.number()
      .nullable()
      .min(-180, 'error.tour.min_map_long')
      .max(180, 'error.tour.max_map_long')
      .notRequired(),
    mapLat: Yup.number()
      .nullable()
      .min(-90, 'error.tour.min_map_lat')
      .max(90, 'error.tour.max_map_lat')
      .notRequired(),
    mapAlt: Yup.number()
      .nullable()
      .min(1, 'error.tour.min_map_alt')
      .max(20, 'error.tour.max_map_alt')
      .notRequired(),
    mapStyle: Yup.string().nullable().notRequired(),
    customStyle: Yup.string().when('map_style', {
      is: '',
      then: Yup.string()
        .required('error.common.field_required')
        .test(
          'validate-input',
          'error.map_box.invalid_style',
          debouncedValidateMapStyleWithPromise
        ),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    mapLightIntensity: Yup.number()
      .required('error.common.field_required')
      .min(0, 'error.tour.min_map_light_intensity')
      .max(1, 'error.tour.max_map_light_intensity'),
    email: Yup.string()
      .nullable()
      .email('error.common.email_invalid')
      .notRequired(),
    websiteUrl: Yup.string().nullable().url('error.common.url').notRequired(),
    facebookUrl: Yup.string().nullable().url('error.common.url').notRequired(),
    instagramUrl: Yup.string().nullable().url('error.common.url').notRequired(),
    twitterUrl: Yup.string().nullable().url('error.common.url').notRequired(),
    snapchatUrl: Yup.string().nullable().url('error.common.url').notRequired(),
    youtubeUrl: Yup.string().nullable().url('error.common.url').notRequired(),
    pinterestUrl: Yup.string().nullable().url('error.common.url').notRequired(),
    allowedIp: Yup.string().test(
      'allowedIp',
      'error.whitelisted_ips.invalid_style',
      ipv4ListValidator
    ),
  });
};

export const getTourBaseOnPathName = (publicTour, tour) => {
  if (isPublicTourPath()) {
    return publicTour;
  } else {
    return tour;
  }
};
//^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$
