import USER_ROLES from '../role';
import { ADMIN_PERMISSIONS } from './admin.premissions';
import { COMPANY_ADMIN_PERMISSIONS } from './companyAdmin.permissions';
import { COMPANY_USER_PERMISSIONS } from './companyUser.permissions';

export const USER_PERMISSIONS = {
  [USER_ROLES.ADMIN]: ADMIN_PERMISSIONS,
  [USER_ROLES.COMPANY_ADMIN]: COMPANY_ADMIN_PERMISSIONS,
  [USER_ROLES.COMPANY_USER]: COMPANY_USER_PERMISSIONS,
};
