import MODES from '../modes';
import USER_ROLES from '../role';

export const COMPANY_ADMIN_PERMISSIONS = {
  tours: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  scenes: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  media: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  menu: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  users: {
    create: true,
    read: false,
    update: true,
    delete: false,
    profile: true,
  },
  update: {
    anotherSection: true,
    roleAndCustomerSection: true,
  },
  profile: {
    anotherSection: true,
    roleAndCustomerSection: false,
  },
  create: {
    anotherSection: true,
    roleAndCustomerSection: true,
  },
  customers: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  customer: {
    create: false,
    read: true,
    update: true,
    delete: false,
  },
  /**
   * Special case
   *
   * 1. User - has many mode
   * [create, edit, profile]
   */
  user: {
    [MODES.CREATE]: {
      roles: [USER_ROLES.COMPANY_USER],
      disabledAllFields: (currentUserRole = '') => false,
      disabledRolesField: (currentUserRole = '') => false,
      disabledCustomersField: false,
    },
    [MODES.EDIT]: {
      roles: [USER_ROLES.COMPANY_USER],
      disabledAllFields: (currentUserRole = '') =>
        [USER_ROLES.COMPANY_ADMIN, USER_ROLES.ADMIN].includes(
          (role) => role === currentUserRole
        ),
      disabledRolesField: (currentUserRole = '') =>
        currentUserRole === USER_ROLES.COMPANY_ADMIN,
      disabledCustomersField: true,
    },
    [MODES.PROFILE]: {
      roles: [USER_ROLES.COMPANY_ADMIN],
      disabledAllFields: (currentUserRole = '') => false,
      disabledRolesField: (currentUserRole = '') => true,
      disabledCustomersField: true,
    },
  },
};
