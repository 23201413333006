import React from 'react';

export const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, { component: Component, property }) => {
      return ({ children }) => (
        <AccumulatedComponents>
          <Component {...property}>{children}</Component>
        </AccumulatedComponents>
      );
    },
    ({ children }) => <>{children}</>
  );
};
