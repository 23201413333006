const BASE_API_URL = process.env.REACT_APP_API_URL;

export default {
  USERS: BASE_API_URL + 'Users',
  USER_CUSTOMERS: BASE_API_URL + 'Users/Customers',
  CUSTOMERS: BASE_API_URL + 'Customers',
  CUSTOMERS_USERS: BASE_API_URL + 'Customers/Users',
  UPLOAD_IMAGE: BASE_API_URL + 'Images/Upload',
  UPLOAD_SCENE_PANO: BASE_API_URL + 'ScenePanorama/Upload',
  UPLOAD_FILE: BASE_API_URL + 'Files/Upload',
  VIDEO_FILE: BASE_API_URL + 'Videos/Upload',
  UPLOAD_SCENE_IMAGE: BASE_API_URL + 'Images/UploadScene',
  GET_PROCESSED_IMAGES: BASE_API_URL + 'Images/ProcessedImages',
  IMAGES: BASE_API_URL + 'Images',
  LOGIN: BASE_API_URL + 'Auth/SignIn',
  REGISTER: BASE_API_URL + 'Accounts/Register',
  VERIFY_EMAIL: BASE_API_URL + 'Accounts/VerifyEmail',
  FORGOT_PASSWORD: BASE_API_URL + 'Users/forgotPassword',
  CHECK_RESET_TOKEN: BASE_API_URL + 'Users/forgotPassword-validateToken',
  RESET_PASSWORD: BASE_API_URL + 'Users/resetPasswordByResetToken',
  CHECK_TOKEN: BASE_API_URL + 'Auth/CheckToken',
  TOURS: BASE_API_URL + 'Tours',
  PUBLIC_TOURS: BASE_API_URL + 'Tours/PublicTours',
  CHECK_LINK_TOUR: BASE_API_URL + 'Hotspots/CheckLinkTour',
  PROJECTS: BASE_API_URL + 'Projects',
  PROJECTS_MENU: BASE_API_URL + 'ProjectMenu',
  SCENES: BASE_API_URL + 'Scenes',
  MEDIAS: BASE_API_URL + 'Medias',
  HOTSPOTS: BASE_API_URL + 'Hotspots',
  MENU: BASE_API_URL + 'Menu',
  TOUR_GROUPS: BASE_API_URL + 'TourGroups',
  MEDIA_GROUPS: BASE_API_URL + 'MediaGroups',
  FEATURED_MEDIA: BASE_API_URL + 'FeaturedMedia',
  LANDING_PAGE: BASE_API_URL + 'LandingPage',
  SUBSCRIPTION: {
    SCOPE: BASE_API_URL + 'SubscriptionScopes',
    PLAN: BASE_API_URL + 'SubscriptionPlans',
    CUSTOMER: BASE_API_URL + 'CustomerSubscriptions',
  },
  PROJECT_SOFT_DELETE: BASE_API_URL + 'Projects/SoftDelete',
  DELETED_ITEMS: BASE_API_URL + 'DeletedItem',
  RESTORE_DELETED_ITEMS: BASE_API_URL + 'DeletedItem/Restore',
  GET_SCENES_PER_TOUR_GROUPS: BASE_API_URL + 'TourGroups/Scenes',
  UPDATE_SCENE_GROUP_POSITION: BASE_API_URL + 'TourGroups/UpdatePosition',
  UPDATE_SCENE_TO_OTHER_GROUP: BASE_API_URL + 'Scenes/ChangeGroup',
  UPDATE_DEFAULT_SCENE: BASE_API_URL + 'TourGroups/DefaultScene',
  AUTO_PILOT: BASE_API_URL + 'AutoPilot',
  WEBSITE: BASE_API_URL + 'Website',
  AGENT_CONTACT: BASE_API_URL + 'AgentContact',
  REAL_ESTATE_PROJECT: BASE_API_URL + 'DomainProject',
  REAL_ESTATE_PROJECT_SOFT_DELETE: BASE_API_URL + 'DomainProject/SoftDelete',
  PROPERTY: BASE_API_URL + 'Property',
  PROPERTY_SOFT_DELETE: BASE_API_URL + 'Property/SoftDelete',
  LISTING: BASE_API_URL + 'Listing',
  GOOGLE_ANALYTICS: BASE_API_URL + 'GoogleAnalytics/ga-token',
};
