export const USER_ROLES = {
  ADMIN: 'Admin',
  COMPANY_ADMIN: 'CompanyAdmin',
  COMPANY_USER: 'CompanyUser',
};

export const USER_ROLE_DISPLAY = {
  Admin: 'Admin',
  CompanyUser: 'Company User',
  CompanyAdmin: 'Company Admin',
};
