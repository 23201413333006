import get from 'lodash/get';

/**
 * Get error message of input
 * implement with react-hook-form
 * @param {string} name name of iput
 * @param {object} field error state
 * @returns errorMsg
 */
export const getErrorMessage = (name, field) =>
  get(field, `${name}.message`, '');
