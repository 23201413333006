import MODES from '../modes';
import USER_ROLES from '../role';

export const COMPANY_USER_PERMISSIONS = {
  tours: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  scenes: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  media: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  menu: {
    create: true,
    read: true,
    update: true,
    delete: true,
  },
  users: {
    create: false,
    read: false,
    update: false,
    delete: false,
    profile: true,
  },
  update: {
    anotherSection: false,
    roleAndCustomerSection: false,
  },
  profile: {
    anotherSection: true,
    roleAndCustomerSection: false,
  },
  create: {
    anotherSection: false,
    roleAndCustomerSection: false,
  },
  customers: {
    create: false,
    read: true,
    update: false,
    delete: false,
  },
  customer: {
    create: false,
    read: true,
    update: false,
    delete: false,
  },
  user: {
    [MODES.CREATE]: {
      roles: [],
      disabledAllFields: (currentUserRole = '') => false,
      disabledRolesField: (currentUserRole = '') => true,
      disabledCustomersField: true,
    },
    [MODES.EDIT]: {
      roles: [],
      disabledAllFields: (currentUserRole = '') => true,
      disabledRolesField: (currentUserRole = '') => true,
      disabledCustomersField: true,
    },
    [MODES.PROFILE]: {
      roles: [USER_ROLES.COMPANY_USER],
      disabledAllFields: (currentUserRole = '') => false,
      disabledRolesField: (currentUserRole = '') => true,
      disabledCustomersField: true,
    },
  },
};
