export default {
  baseUrl: process.env.REACT_APP_BASE_URL || '',
  previewUrl: process.env.REACT_APP_PREVIEW_URL || '',
  viewUrl: process.env.REACT_APP_VIEW_URL || '',
  sitesUrl: process.env.REACT_APP_SITES_URL || '',
  ga4ReportingId: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || null,
  ga4TrackingId: process.env.REACT_APP_GA4_MEASUREMENT_ID || '',
  modules: {
    publicTour: process.env.REACT_APP_FEATURE_PUBLIC_TOUR === 'true',
    realEstateContent: process.env.REACT_APP_FEATURE_REAL_ESTATE === 'true',
  },
};
