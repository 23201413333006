import * as ACTION_TYPE from './actionTypes';

export const initiate = () => ({ type: ACTION_TYPE.INITIATE });

export const setCustomer = (customer) => ({
  type: ACTION_TYPE.SET_CUSTOMER,
  customer,
});

export const toggleHeaderModal = (isOpen, typeModal) => ({
  type: ACTION_TYPE.TOGGLE_HEADER_MODAL,
  typeModal: typeModal,
  isOpen,
});

export const setPublicTour = (publicTour) => ({
  type: ACTION_TYPE.SET_PUBLIC_TOUR,
  publicTour,
});

export const setPublicTours = (publicTours) => ({
  type: ACTION_TYPE.SET_PUBLIC_TOURS,
  publicTours,
});

export const loadPublicTours = () => ({
  type: ACTION_TYPE.LOAD_PUBLIC_TOURS,
});

export const setProject = (project) => ({
  type: ACTION_TYPE.SET_PROJECT,
  project,
});

export const setProjects = (projects) => ({
  type: ACTION_TYPE.SET_PROJECTS,
  projects,
});

export const loadProjects = () => ({
  type: ACTION_TYPE.LOAD_PROJECTS,
});

export const setTour = (tour) => ({
  type: ACTION_TYPE.SET_TOUR,
  tour,
});

export const setTours = (tours) => ({
  type: ACTION_TYPE.SET_TOURS,
  tours,
});

export const loadTours = () => ({
  type: ACTION_TYPE.LOAD_TOURS,
});

export const set360Data = (data) => ({
  type: ACTION_TYPE.SET_360_DATA,
  data,
});

export const set360Images = (images, imageType) => ({
  type: ACTION_TYPE.SET_360_IMAGES,
  images,
  imageType,
});

export const setLensflare = (lensflare) => ({
  type: ACTION_TYPE.SET_LENSFLARE,
  lensFlareLocation: lensflare,
});

export const refreshTourDetail = () => ({
  type: ACTION_TYPE.REFRESH_TOUR_DETAIL,
});

export const refreshProjectDetail = () => ({
  type: ACTION_TYPE.REFRESH_PROJECT_DETAIL,
});

export const logout = () => ({
  type: ACTION_TYPE.SIGN_OUT,
});

export const setUser = (user) => ({
  type: ACTION_TYPE.SET_USER,
  user,
});

export const checkUserRole = () => ({
  type: ACTION_TYPE.CHECK_USER_ROLE,
});

export const setUserRole = (role) => ({
  type: ACTION_TYPE.SET_USER_ROLE,
  role,
});

export const setUserCustomers = (customers) => ({
  type: ACTION_TYPE.SET_USER_CUSTOMERS,
  customers,
});

export const setUserName = (userName) => ({
  type: ACTION_TYPE.SET_USER_NAME,
  userName,
});

export const refreshCustomers = () => ({
  type: ACTION_TYPE.REFRESH_CUSTOMERS,
});

export const setTourNameModal = (modal) => ({
  type: ACTION_TYPE.SET_TOUR_NAME_MODAL,
  modal,
});

export const setSceneNameModal = (modal) => ({
  type: ACTION_TYPE.SET_SCENE_NAME_MODAL,
  modal,
});

export const setAddSceneModal = (modal) => ({
  type: ACTION_TYPE.SET_ADD_SCENE_MODAL,
  modal,
});

export const checkIsShowClientWelcomePopup = () => ({
  type: ACTION_TYPE.SET_IS_SHOW_CLIENT_WELCOME_POPUP,
});

export const setWebsites = (websites) => ({
  type: ACTION_TYPE.SET_WEBSITES,
  websites,
});

export const setDrawShape = (mediaId) => {
  return {
    type: ACTION_TYPE.SET_MEDIA_ID,
    mediaId,
  };
};

export const setShowMapFloorPlanHp = (isShowMFHp) => {
  return {
    type: ACTION_TYPE.SET_IS_SHOW_MF_HP,
    isShowMFHp,
  };
};

export const setShowVideoInline = (isShowInline) => {
  return {
    type: ACTION_TYPE.SET_INLINE_VIDEO,
    isShowInline,
  };
};

export const setRotateAxis = (rotateAxis) => {
  return {
    type: ACTION_TYPE.ROTATE_AXIS,
    rotateAxis,
  };
};

export const setOgRotate = (originalRotate) => {
  return {
    type: ACTION_TYPE.SET_ORIGINAL_INLINE_ROTATE,
    originalRotate,
  };
};

export const updateStore = (state) => {
  return {
    type: ACTION_TYPE.SET,
    ...state,
  };
};
