// LOCAL STORAGE
const LOCAL_KEY = {
  USER: 'user',
  USER_ID: 'userId',
  CUSTOMER: 'customer',
  PUBLIC_TOUR: 'publicTour',
  PROJECT: 'project',
  TOUR: 'tour',
  TOKEN: 'token',
  GA: {
    TOKEN: 'gaToken',
    EXPIRY: 'gaExpiry',
  },
};

export default LOCAL_KEY;
