import configs from 'configs';

export const DEFAULT_MENU_STYLE = 'default';
export const DEFAULT_HOTSPOT_STYLE = 'default';

export const HOTSPOT_UI = {
  DEFAULT: 'default',
  CALLOUT: 'callout',
  AIR_BALLOON: 'air-balloon',
};

export const MENU_STYLE = [
  {
    style: DEFAULT_MENU_STYLE,
    label: 'Default',
  },
  {
    style: 'gallery',
    label: 'Gallery',
  },
  {
    style: 'school',
    label: 'School',
  },
  {
    style: 'aqua',
    label: 'Aqua',
  },
  {
    style: 'prime',
    label: 'Prime',
  },
  {
    style: 'avoriaz',
    label: 'Avoriaz',
  },
  {
    style: 'mobihome',
    label: 'MobiHome',
  },
  {
    style: 'clear-space',
    label: 'Clear Space',
  },
  {
    style: 'resort',
    label: 'Resort',
  },
];

export const HOTSPOT_STYLES = [
  {
    style: DEFAULT_HOTSPOT_STYLE,
    label: 'Default',
    image: configs.baseUrl + '/assets/images/hotspot-styles/default.png',
  },
  {
    style: 'callout',
    label: 'Callout',
    image: configs.baseUrl + '/assets/images/hotspot-styles/callout.png',
  },
  {
    style: 'air-balloon',
    label: 'Air Balloon',
    image: configs.baseUrl + '/assets/images/hotspot-styles/air-balloon.png',
  },
];
