import {
  DEFAULT_SCOPES_DATA,
  PLAN_SCOPES,
  PLAN_SCOPE_KEYS,
  USER_ROLES,
  SITE_SCOPE,
} from 'consts';

const CAN_DO = true;
const CAN_NOT_DO = false;

export const checkScopes = (value = -1) => {
  switch (value) {
    case -1:
      return PLAN_SCOPES.UNLIMITED;
    case 0:
      return PLAN_SCOPES.NOT_ALLOWED;
    default:
      return PLAN_SCOPES.NORMAL;
  }
};

export const checkAllowToDo = ({
  scopes,
  usage,
  totalInTour,
  scopeName,
  role,
}) => {
  // Admin can do anything
  if (role === USER_ROLES.ADMIN) return true;

  // scope
  const { value: limit } = scopes[scopeName];
  const { value: currentUsage } = usage[scopeName];

  // -1 mean UNLIMITED
  if (limit === SITE_SCOPE.UNLIMITED) return CAN_DO;

  // 0 mean NOT ALLOW TODO
  if (limit === 0) return CAN_NOT_DO;

  //limit amount of projects
  if (currentUsage < limit) return CAN_DO;

  return CAN_NOT_DO;
};

/**
 * Ensure Subscription data
 * @param {object} subscriptionData Subscription Data
 */
export const ensureSubscriptionData = (subscriptionData) =>
  subscriptionData.reduce((preValue, currentValue) => {
    const { key, value } = currentValue;
    switch (key) {
      case PLAN_SCOPE_KEYS.SCOPE_TYPE:
        return { ...preValue, scopeType: value };
      case PLAN_SCOPE_KEYS.PROJECT_COUNT:
        return { ...preValue, project: { ...preValue.project, value: +value } };
      case PLAN_SCOPE_KEYS.TOUR_COUNT:
        return { ...preValue, tour: { ...preValue.tour, value: +value } };
      case PLAN_SCOPE_KEYS.SCENE_COUNT:
        return { ...preValue, scene: { ...preValue.scene, value: +value } };
      case PLAN_SCOPE_KEYS.MEDIA_COUNT:
        return { ...preValue, media: { ...preValue.media, value: +value } };
      case PLAN_SCOPE_KEYS.USER_COUNT:
        return { ...preValue, user: { ...preValue.user, value: +value } };
      case PLAN_SCOPE_KEYS.SCENE_PER_TOUR_COUNT:
        return {
          ...preValue,
          scene: { ...preValue.scene, limitPerTour: +value },
        };
      case PLAN_SCOPE_KEYS.MEDIA_PER_TOUR_COUNT:
        return {
          ...preValue,
          media: { ...preValue.media, limitPerTour: +value },
        };
      default:
        return preValue;
    }
  }, DEFAULT_SCOPES_DATA);
