export const ERROR = {
  name: '',
  tagline: '',
  location: '',
  menuHighlightColor: '',
  calloutHeaderColor: '',
  calloutSubHeaderColor: '',
  lightboxBGColor: '',
  lightboxHeaderColor: '',
  lightboxBodyTextColor: '',
  facebookPixelId: '',
  googleAnalyticTrackingId: '',
  brandColor2: '',
  logoUrl: '',
  introImageUrl: '',
  autorotate: '',
  autorotateSpeed: '',
  defaultViewingAngle: '',
  friendlyName: '',
  mapLong: '',
  mapLat: '',
  mapAlt: '',
  mapLightIntensity: '',
  mapLightColor: '',
  accessCode: '',
};

export const VALIDATE_RULE = {
  name: {
    required: true,
  },
  location: {
    required: true,
  },
  tagline: {
    required: true,
  },
  facebookPixelId: {},
  googleAnalyticTrackingId: {},
  logoUrl: {},
  introImageUrl: {},
  autorotate: {},
  autorotateSpeed: {
    required: true,
    min: -2,
    max: 2,
  },
  defaultViewingAngle: {
    required: true,
    min: 20,
    max: 120,
  },
  friendlyName: {},
  mapLong: {
    required: true,
    min: -180,
    max: 180,
  },
  mapLat: {
    required: true,
    min: -90,
    max: 90,
  },
  mapAlt: {
    required: true,
    min: 1,
    max: 20,
  },
  mapLightIntensity: {
    required: true,
    min: 0,
    max: 1,
  },
  accessCode: {
    required: false,
    minLength: 4,
    regex: /^[a-zA-Z0-9-]+$/i,
  },
  email: {
    email: true,
  },
  websiteUrl: {
    url: true,
  },
  facebookUrl: {
    url: true,
  },
  instagramUrl: {
    url: true,
  },
  twitterUrl: {
    url: true,
  },
  snapchatUrl: {
    url: true,
  },
  youtubeUrl: {
    url: true,
  },
  pinterestUrl: {
    url: true,
  },
};

export const SCENE_RATIO = [
  {
    value: '2',
    label: '2:1',
  },
  {
    value: '4',
    label: '4:1',
  },
];

export const SCENE_RATIO_VALUE = [2, 4];

export const DEFAULT_VIEWING_ANGLE = 60;

const defaultCoordsData = {
  mapLong: null,
  mapLat: null,
  mapAlt: null,
};
export const defaultModalMapCoords = {
  mapLong: 106.4150337,
  mapLat: 10.7546664,
  mapAlt: 10,
};

export const DEFAULT_TOUR_DATA = {
  name: '',
  tagline: '',
  location: '',
  friendlyName: '',
  menuHighlightColor: '#F73D3C',
  calloutHeaderColor: '#000000',
  calloutSubHeaderColor: '#FFFFFF',
  lightboxBGColor: '#000000',
  lightboxBGColor1: '',
  lightboxBGColor2: '',
  lightboxBGColor3: '',
  lightboxHeaderColor: '#FFFFFF',
  lightboxBodyTextColor: '#FFFFFF',
  facebookPixelId: '',
  googleAnalyticTrackingId: '',
  brandColor2: '',
  logoUrl: '',
  introImageUrl: '',
  autorotate: true,
  autorotateSpeed: 1,
  defaultViewingAngle: 60,
  mapCoordinates: [],
  ...defaultCoordsData,
  mapLightIntensity: 0.5,
  mapLightColor: 'white',
  mapBoxStyle: null,
  hotspotStyle: 'default',
  lightboxBlur: true,
  sceneRatio: 2,
  accessCode: '',
  enableGyroscope: true,
  active: true,
};

export const TOUR_FIELD_NOT_UPDATE_BY_INPUT = [
  'friendlyName',
  'menuHighlightColor',
  'calloutHeaderColor',
  'calloutSubHeaderColor',
  'lightboxBGColor',
  'lightboxBGColor1',
  'lightboxBGColor2',
  'lightboxBGColor3',
  'lightboxHeaderColor',
  'lightboxBodyTextColor',
  'brandColor2',
  'logoUrl',
  'introImageUrl',
  'mapCoordinates',
  'mapLong',
  'mapLat',
  'mapAlt',
  'mapLightColor',
  'enableGyroscope',
  'active',
];

export const TOUR = {
  ERROR,
  VALIDATE_RULE,
  SCENE_RATIO,
  DEFAULT_TOUR_DATA,
  SCENE_RATIO_VALUE,
  DEFAULT_VIEWING_ANGLE,
  TOUR_FIELD_NOT_UPDATE_BY_INPUT,
};

export default TOUR;
