import { put, select } from 'redux-saga/effects';

import { checkToken } from 'services/authorization';
import { getTourDetail, getToursBySearchQuery } from 'services/tours';
import { getProjectsBySearchQuery } from 'services/projects';

import * as ACTION_TYPE from './actionTypes';
import { loadCustomersByRole } from './modules/customers';
import { loadPublicToursByRole } from './modules/publicTours';
import { getTourBaseOnPathName } from 'features/tours/utils';

const selectUserAndRole = ({ user }) => user;
const selectCustomerId = ({ customer }) => customer?.id || '';
const selectProjectId = ({ project }) => project?.id || '';
const selectProject = ({ project }) => project;
const selectProjects = ({ projects }) => projects;
const selectTourId = ({ tour }) => tour?.id || '';
const selectPublicTourId = ({ publicTour }) => publicTour?.id || '';

function kickStart() {
  return new Promise((resolve) => {
    setTimeout(() => resolve('DONE'), 0);
  });
}

function* initiate() {
  try {
    const result = yield kickStart();
    if (result) {
      yield put({
        type: ACTION_TYPE.INITIATED,
      });
    }
  } catch (error) {}
}

const pagePath = [
  '/login',
  '/password/forgot',
  '/password/reset',
  '/account/verify',
];

const goToLogin = () => {
  if (pagePath.includes(window.location.pathname)) {
    return;
  } else if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};

function* checkUserRole() {
  try {
    const uId = localStorage.getItem('userId');
    if (!uId) return goToLogin();

    const user = yield select(selectUserAndRole);
    if (!user.role) {
      const tokenRes = yield checkToken();
      const { role, userId } = tokenRes.data.data;
      if (!role || userId !== uId) {
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        return goToLogin();
      }
      yield put({
        type: ACTION_TYPE.SET_USER,
        user: {
          id: uId,
          role,
        },
      });
    }

    yield loadCustomersByRole();
    yield loadPublicToursByRole();
    yield put({ type: ACTION_TYPE.LOAD_PROJECTS });
    yield put({ type: ACTION_TYPE.LOAD_TOURS });
  } catch (error) {
    console.error('Fail to check token: ', error);
  }
}

function* refreshTourDetail() {
  try {
    const tourId = yield select(selectTourId);
    const publicTourId = yield select(selectPublicTourId);
    const currentTourId = getTourBaseOnPathName(publicTourId, tourId);

    if (!currentTourId) return;

    const response = yield getTourDetail(currentTourId);
    const tour = response.data.data;

    const newTourData = {
      id: tour.id,
      name: tour.name,
      friendlyName: tour.friendlyName,
      isModified: tour.isModified,
      active: tour.active,
      accessCode: tour.accessCode,
      isPublic: tour.isPublic,
      mapStyle: tour.mapBoxStyle,
      hotspotStyle: tour.hotspotStyle,
    };

    if (tour.id === currentTourId) {
      if (tour.isPublic) {
        yield put({
          type: ACTION_TYPE.SET_PUBLIC_TOUR,
          publicTour: newTourData,
        });
      } else {
        yield put({
          type: ACTION_TYPE.SET_TOUR,
          tour: newTourData,
        });
      }
    }
  } catch (error) {}
}

function* refreshProjectDetail() {
  try {
    const projectId = yield select(selectProjectId);
    const projectList = yield select(selectProjects);
    const projectDetail = yield select(selectProject);
    if (!projectId) {
      return;
    }

    if (projectDetail.id === projectId) {
      yield put({
        type: ACTION_TYPE.SET_PROJECT,
        project: {
          id: projectDetail.id,
          name: projectDetail.name,
          active: projectDetail.active,
          accessCode: projectDetail.accessCode,
        },
      });
      const newProjectDetail = {
        customerId: projectDetail.customerId,
        accessCode: projectDetail.accessCode,
        name: projectDetail.name,
        active: projectDetail.active,
        id: projectDetail.id,
        uid: projectDetail.uid,
        createdAt: projectDetail.createdAt,
        updatedAt: projectDetail.updatedAt,
        isDeleted: projectDetail.isDeleted,
        deletedAt: projectDetail.deletedAt,
      };
      const newProjectList = projectList.map((item) => {
        if (item.id === newProjectDetail.id) {
          return newProjectDetail;
        }
        return item;
      });
      yield put({
        type: ACTION_TYPE.SET_PROJECTS,
        projects: newProjectList,
      });
    }
  } catch (error) {}
}

function* loadProjectsFromCurrentCustomer() {
  try {
    const customerId = yield select(selectCustomerId);
    if (customerId) {
      const projectResponse = yield getProjectsBySearchQuery({
        customerId,
      });

      const projects =
        projectResponse.data.data.map((item) => {
          item.updatedAt = item.updatedAt ? item.updatedAt : item.createdAt;
          return item;
        }) || [];

      // TODO: check if no project => show popup ask user create first project

      yield put({
        type: ACTION_TYPE.SET_PROJECTS,
        projects,
      });

      if (projects.length) {
        const projectId = yield select(selectProjectId);
        const hasProject = projects.find((project) => project.id === projectId);
        if (!hasProject) {
          yield put({
            type: ACTION_TYPE.SET_PROJECT,
            project: { id: projects[0].id, name: projects[0].name },
          });
        }
      } else {
        yield put({
          type: ACTION_TYPE.SET_PROJECT,
          project: { id: '', name: '' },
        });
      }
    } else {
      // Clear current project
      yield put({
        type: ACTION_TYPE.SET_PROJECTS,
        projects: [],
      });
    }
  } catch (error) {}
}

function* loadToursFromCurrentCustomer() {
  try {
    const customerId = yield select(selectCustomerId);

    if (customerId) {
      const tourResponse = yield getToursBySearchQuery({
        customerId,
      });
      const tours =
        tourResponse.data.data.map((item) => {
          item.updatedAt = item.updatedAt || item.createdAt;
          return item;
        }) || [];

      yield put({
        type: ACTION_TYPE.SET_TOURS,
        tours,
      });
      if (tours.length) {
        const tourId = yield select(selectTourId);
        const hasTour = tours.find((tour) => tour.id === tourId);
        if (!hasTour) {
          yield put({
            type: ACTION_TYPE.SET_TOUR,
            tour: { id: tours[0].id, name: tours[0].name },
          });
        }
      } else {
        yield put({
          type: ACTION_TYPE.SET_TOUR,
          tour: { id: '', name: '', friendlyName: '' },
        });
      }
    } else {
      // Clear current tours
      yield put({
        type: ACTION_TYPE.SET_TOURS,
        tours: [],
      });
    }
  } catch (error) {}
}

const middlewares = {
  initiate,
  checkUserRole,
  loadProjectsFromCurrentCustomer,
  loadToursFromCurrentCustomer,
  refreshProjectDetail,
  refreshTourDetail,
};

export default middlewares;
